import React, { useState } from "react";
import {
  Button,
  FormControl,
  Input,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useSendMessageMutation } from "../booking/apiSlice";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const toast = useToast();
  const [sendMessage, isLoading, error] = useSendMessageMutation();
  const handleChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, message } = formData;

    if (!name || !email || !message) {
      toast({
        title: "Form Validation",
        status: "error",
        description: "Please fill in all the required fields.",
        isClosable: true,
      });
      return;
    }

    if (!validateEmail(email)) {
      toast({
        title: "Email Validation",
        status: "error",
        description: "Please enter a valid email address.",
        isClosable: true,
      });
      return;
    }
    console.log(formData, "sending form data");
    await sendMessage(formData).unwrap();
    // Clear form data and show a success message
    setFormData({
      name: "",
      email: "",
      message: "",
    });

    toast({
      title: "Form Submission",
      status: "success",
      description: "Successfully submitted the form",
      isClosable: true,
    });
  };

  return (
    <>
      <div className="bg-[#14274A] min-h-[100px] w-full"></div>
      <div className="min-h-screen">
        <div className="max-w-7xl mx-auto mt-16 px-4 xl:px-8">
          <h1 className="flex justify-center text-[#14274A] text-4xl text-center font-extrabold font-garamond xl:ml-4">
            WE ARE HERE FOR YOU
          </h1>
          <p className="text-[#14274A] text-md font-light flex text-center mt-4 w-full font-montserrat">
            At Zeebar Resort, we take our customers seriously. Do you have any
            enquiries, complaints or requests, please forward it to our support
            desk and we will get back to you as soon as possible.
          </p>
          <div className="flex flex-col lg:flex-row py-8 lg:space-x-8 xl:space-x-16 space-y-16 lg:space-y-0 px-8 xl:px-4">
            <form
              className="text-black w-full space-y-8"
              onSubmit={handleSubmit}
            >
              <FormControl id="userName" isRequired>
                <Input
                  placeholder="Your Name"
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full p-2"
                  style={{ border: "0.5px solid grey" }}
                />
              </FormControl>

              <FormControl id="email" isRequired>
                <Input
                  placeholder="Your Email"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full p-2"
                  style={{ border: "0.5px solid grey" }}
                />
              </FormControl>

              <FormControl id="message" isRequired>
                <Textarea
                  placeholder="Drop your message"
                  type="text"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className="w-full p-2"
                  style={{ border: "0.5px solid grey", height: "200px" }}
                />
              </FormControl>

              <Button
                type="submit"
                colorScheme="yellow"
                bgColor="#E0B973"
                className="w-full p-2 text-white rounded-md font-montserrats"
              >
                Submit
              </Button>
            </form>
            <div className="w-full ">
              <iframe
                title="Google Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3558.5857166380715!2d89.09537137548904!3d26.88490046128514!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e3c1ea4b5e4529%3A0xfdf2eaf12a96d8a8!2sZeebar%20Resort!5e0!3m2!1sen!2sbt!4v1718960124529!5m2!1sen!2sbt"
                width="100%"
                height="410"
                style={{ border: 0 }}
                allowFullScreen=""
                className="hidden lg:block lg:w-[600px]"
                referrerPolicy="no-referrer"
                sandbox
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
